const VisibleCategories = [
    'drama-series',
    'comedy-series',
    'documentary-series-or-special',
    'limited-series-television-movie',
    'variety-programs',
    'nonfiction-series',
    'animated-programs',
    'childrens-programs',
    'nonfiction-series',
    'sports-series',
    'foreign-language-series',
]

export default VisibleCategories;