import { APICore } from "../apiCore";

const api = new APICore();


function listEvents() {
    return api.get(`/event`);
}

function getEventBySlug(eventSlug) {
    return api.get(`/event/${ eventSlug }`);
}

function getEventsByShowSlug(showSlug) {
    return api.get(`/show/${ showSlug }/event`);
}

export { listEvents, getEventBySlug, getEventsByShowSlug };
