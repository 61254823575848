import React, {useLayoutEffect, useState, useRef} from 'react'
import { Link } from 'react-router-dom'
// import Moment from 'react-moment';
import $ from 'jquery'
// import { Event, TrackingEvents } from '../../../Common/Helpers/Tracking'
// import videoPlaceholder from '../../Common/_assets/img/video-placeholder.jpg'
import iconPlay from '../../../../Common/_assets/img/icon/icon-play.svg'
import videoPlaceholderBlank from '../../../../Common/_assets/img/placeholder/videoplaceholderblank.gif'

const TitleMediaMP4 = ({mediaPosterUrl, mediaUrl}) => {

    // const { get } = useContext(AppContext)
    const [thumbnail, setThumbnail] = useState()

    const videoThumbRef = useRef()
    const videoFullRef = useRef()
    const videoFullContainer = useRef()


    useLayoutEffect( () => {
      // let s3Url = "https://jwlfyc-public-us-east-1.s3.amazonaws.com/"
        let subscribed=true
        if (subscribed) {
              setThumbnail(mediaPosterUrl)
          $(videoFullRef.current).bind("ended", function() {
             onVideoFullEnd()
          });

        }
        return ()=>{subscribed=false}
    }, [mediaPosterUrl, mediaUrl])


    const playVideo = () => {
      // videoFullRef.current.internalPlayer.seekTo(1)
      videoFullRef.current.play()
      setTimeout(()=>{
          $(videoThumbRef.current).addClass('disabled')
      }, 600)
      $(videoFullContainer.current).removeClass('disabled')
    }

    const onVideoFullEnd = () => {
        $(videoThumbRef.current).removeClass('disabled')
        setTimeout(()=>{
            $(videoFullContainer.current).addClass('disabled')
        }, 600)
    }




    return (


      <div className="col-12">

        <div className="fyseevideo__container  ">

          <div className="fyseevideo__inner">

              {(mediaUrl)?(
                  <div className="videothumb__container" ref={videoThumbRef}>
                  <div className="video__container">
                  <Link className=""  onClick={(e)=>{e.preventDefault();playVideo()}} to="/">
                    <img src={iconPlay} className="icon__play" alt="FYSEE" />
                    {(thumbnail)?(
                      <img src={thumbnail} className="videothumbnail__image videothumb__cursor" alt="FYSEE" />
                    ):("")}
                  <img src={videoPlaceholderBlank} className="" alt="Netflix FYC" />
                  </Link>
                  </div>
                  </div>
              ):("")}



              {(mediaUrl)?(


                <div className="videofull__container disabled" ref={videoFullContainer}>
                  <div className="video__container">
                    <video ref={videoFullRef} controls playsInline poster={thumbnail}>
                      <source src={mediaUrl} type='video/mp4' />
                    </video>
                    <img src={videoPlaceholderBlank} className="d-none" alt="Netflix FYC" />
                  </div>
                </div>

              ):("")}

        </div>


        </div>



      </div>


    )
}

export default TitleMediaMP4
