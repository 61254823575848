import { APICore } from "../apiCore";

const api = new APICore();

function getShowGenrePageData(genreSlug) {
    return api.get(`/genre/${ genreSlug }`, {});
}

function getShowGenrePageDataPGA(genreSlug) {
    return api.get(`/pga/genre/${ genreSlug }`, {});
}

function getShowGenrePageDataVES(genreSlug) {
    return api.get(`/ves/genre/${ genreSlug }`, {});
}

export { getShowGenrePageData, getShowGenrePageDataPGA, getShowGenrePageDataVES };