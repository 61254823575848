import { APICore } from "../apiCore";

const api = new APICore();

function getLandingPageData() {
    return api.get('/landing');
}

function getPGAPageData() {
    return api.get('/pga');
}

function getVESPageData() {
    return api.get('/ves');
}

export { getLandingPageData, getPGAPageData, getVESPageData };
