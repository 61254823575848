import { Button, Form, Modal } from "react-bootstrap";
import netflixLogo from '../../../Common/_assets/img/logo/netflix-logo-ribbon.png'
import React, { useState, useRef, useEffect } from "react";

import { fetchGuilds } from "../../../utils/api/helpers/guild";
import { createSubscription } from "../../../utils/api/helpers/subscription";

const EventSignUp = () => {

    const [ email, setEmail ] = useState( null ),
        [ name, setName ] = useState( null ),
        [ guildAffiliation, setGuildAffiliation ] = useState( null ),
        [ displaySignUpModal, setDisplaySignUpModal ] = useState( false ),
        [ success, setSuccess ] = useState( false ),
        [ formSubmitted, setFormSubmitted ] = useState( false );

    const [ guildList, setGuildList ] = useState( [] );

    const nameInputRef = useRef();
    const emailInputRef = useRef();
    const guildInputRef = useRef();

    const localStorageDisplaySignUpModalValue = window.localStorage.getItem('localStorageDisplaySignUpModalValue')

    useEffect(() => {
        fetchGuilds().then(result =>
            setGuildList(result.data))
    }, []);

    useEffect( () => {
        let subscribed = true
        if (subscribed) {
            // console.log('localStorage', localStorageDisplaySignUpModalValue)
            if (localStorageDisplaySignUpModalValue === null) {
                // console.log('localStorage is null', localStorageDisplaySignUpModalValue)
                window.localStorage.setItem('localStorageDisplaySignUpModalValue', true)
                setDisplaySignUpModal(true)
            } else if (localStorageDisplaySignUpModalValue === 'true') {
                // console.log('localStorage is true', localStorageDisplaySignUpModalValue)
                setDisplaySignUpModal(true)
            } else if (localStorageDisplaySignUpModalValue === 'false') {
                // console.log('localStorage is false', localStorageDisplaySignUpModalValue)
                setDisplaySignUpModal(false)
            }
        }
        return () => subscribed = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ localStorageDisplaySignUpModalValue ])

    const clearForm = () => {
        setEmail(null)
        setName(null)
        setGuildAffiliation(null)
        setSuccess(false)
        setFormSubmitted(false)
        if (nameInputRef.current) {
            nameInputRef.current.value=""
        }
        if (emailInputRef.current) {
            emailInputRef.current.value=""
        }
        if (guildInputRef.current) {
            guildInputRef.current.value=""
        }
    }

    const submitForm = (e) => {
        e.preventDefault();
        if (validateEmail(email)) {
            createSubscription({ name, email, guildId: guildAffiliation })
            .then((r) => {
                // console.log("RE", r);
                setSuccess(true)
                setFormSubmitted(true)
                setTimeout(()=>{
                    closeModal()
                }, 1000)
            });
        }
        else {
            emailInputRef.current.value = "";
            setSuccess(false);
            setFormSubmitted(true);
        }
        
    }

    const validateEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; 
        return emailPattern.test(email);
    }
    const closeModal = () => {
        clearForm()
        setDisplaySignUpModal(false)
        window.localStorage.setItem('localStorageDisplaySignUpModalValue',false)
    }

    return (
        <Modal centered className={'emailsignup-modal-container'} show={ displaySignUpModal } onHide={ () => {closeModal()}}>
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>

            <Modal.Body >

                <div className={'my-4 pb-2'}>
                    <div className={'d-flex align-items-center justify-content-center'}>
                        <div className={'d-flex align-items-center justify-content-center pe-3'}>
                            <img src={netflixLogo} className="header__logo" alt="Netflix" style={{maxWidth:'15px'}} />
                        </div>
                        <div className={'d-flex align-items-center justify-content-center'}>
                            <span className={''} style={{
                                fontSize:'14px',
                                fontWeight:'300',
                                color:'#aaa',
                            }}>FYC</span>
                        </div>
                    </div>
                </div>

                <Form>
                    {
                        !success ?
                            <>
                                <div className={'text-center'}>
                                    <div className={'h4'}>
                                        Stay Informed
                                    </div>
                                    <p className={'mb-4 pb-2'}>
                                        Sign up to receive information about the shows you love, upcoming events and
                                        more.
                                    </p>
                                </div>

                                <Form.Group controlId={"NameInput"} className={'name-input mb-2'}>
                                    <Form.Control value={name}
                                                  ref={nameInputRef}
                                                  onChange={e => setName(e.target.value)}
                                                  placeholder={"Your Name"}/>
                                </Form.Group>
                                {
                                    formSubmitted && !nameInputRef.current.value ?
                                        <div className={'text-red text-small mb-2'}>Please enter your
                                            name.</div>
                                        : null
                                }
                                <Form.Group controlId={"EmailInput"} className={'email-input mb-2'}>
                                    <Form.Control value={email}
                                                  ref={emailInputRef}
                                                  onChange={e => setEmail(e.target.value)}
                                                  placeholder={"your@email.com"}/>
                                </Form.Group>
                                {
                                    formSubmitted && !emailInputRef.current.value ?
                                        <div className={'text-red text-small mb-2'}>Please enter your valid
                                            email
                                            address.</div>
                                        : null
                                }
                                <Form.Group controlId='GuildInput' className={'guild-input mb-2'}>
                                    <Form.Control as='select'
                                                  required
                                                  ref={guildInputRef}
                                                  value={guildAffiliation || ''}
                                                  onChange={(e) => {
                                                      setGuildAffiliation(e.target.value);
                                                  }}>
                                        <option value={null}>Select Guild Affiliation</option>
                                        {
                                            guildList.map((ga, i) => {
                                                return <option key={i} value={ga.guild_id}>{ga.name}</option>
                                            })
                                        }
                                    </Form.Control>
                                </Form.Group>
                                {
                                    formSubmitted && !guildInputRef.current.value ?
                                        <div className={'text-red text-small mb-2'}>Please enter your valid
                                            email
                                            address.</div>
                                        : null
                                }
                                <div className={'text-center mt-4'}>
                                    {
                                        !success && !formSubmitted ?
                                            <div className={'d-flex justify-content-center'}>
                                                <Button variant="primary"
                                                        type={'submit'}
                                                        onClick={(e) => submitForm(e)}>
                                                    {'SUBMIT'}
                                                </Button>
                                                <Button variant="secondary"
                                                        className={'ms-4 button__box button__grey'}
                                                        onClick={(e) => closeModal()}>
                                                    {'CLOSE'}
                                                </Button>
                                            </div>
                                            : null
                                    }
                                    {
                                        !success && formSubmitted ? <Button variant="primary"
                                                                            onClick={(e) => clearForm()}>{'Try Again'}</Button>
                                            : null
                                    }
                                </div>
                            </>
                            :
                            <p style={{textAlign: 'center', color: '#fff'}}>Thank you! Please check your inbox.</p>
                    }

                </Form>

            </Modal.Body>

            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    );
}

export default EventSignUp;
