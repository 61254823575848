import axios from 'axios';

//import config from '../../config';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = '';

// intercepting to capture errors
axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        let message;

        if (error && error.response && (error.response.status === 404 || error.response.status === 301)) {
             window.location.href = '/';
        } else if (error && error.response && error.response.status === 403) {
            window.location.href = '/';
        } else {
            switch (error.response.status) {
                case 401:
                    message = 'Invalid credentials';
                    break;
                case 403:
                    message = 'Access Forbidden';
                    break;
                case 404:
                    message = 'Sorry! the data you are looking for could not be found';
                    break;
                default: {
                    message =
                        error.response && error.response.data ? error.response.data['message'] : error.message || error;
                }
            }
            return Promise.reject(message);
        }
    }
);

const AUTH_SESSION_KEY = 'hyper_user';

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
    if (token) axios.defaults.headers.common['Authorization'] = 'JWT ' + token;
    else delete axios.defaults.headers.common['Authorization'];
};

const getUserFromSession = () => {
    const user = sessionStorage.getItem(AUTH_SESSION_KEY);
    return user ? (typeof user == 'object' ? user : JSON.parse(user)) : null;
};
class APICore {

    /**
     * Fetches data from given url
     */
    get = async (url, params) => {
        let response;
        if (params) {
            let queryString = params
                ? Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&')
                : '';
            response = await axios.get(`/api/v3${url}?${queryString}`, params);
        } else {
            response = await axios.get(`/api/v3${url}`, params);
        }
        return response;
    };

    getFile = (url, params) => {
        let response;
        if (params) {
            let queryString = params
                ? Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&')
                : '';
            response = axios.get(`/api${url}?${queryString}`, { responseType: 'blob' });
        } else {
            response = axios.get(`/api${url}`, { responseType: 'blob' });
        }
        return response;
    };

    getMultiple = (urls, params) => {
        const reqs = [];
        let queryString = '';
        if (params) {
            queryString = params
                ? Object.keys(params)
                    .map((key) => key + '=' + params[key])
                    .join('&')
                : '';
        }

        for (const url of urls) {
            reqs.push(axios.get(`${url}?${queryString}`));
        }
        return axios.all(reqs);
    };

    /**
     * post given data to url
     */
    create = async (url, data) => {
        return axios.post(`/api/v3${ url }`, data);
    };

    /**
     * Updates patch data
     */
    updatePatch = (url, data) => {
        return axios.patch(`/api/v3${ url }`, data);
    };

    /**
     * Updates data
     */
    update = (url, data) => {
        return axios.put(`/api/v3${ url }`, data);
    };

    /**
     * Deletes data
     */
    delete = (url) => {
        return axios.delete(`/api/v3${ url }`);
    };

    /**
     * post given data to url with file
     */
    file = async (url, data, report) => {

        const files = new FormData();
        for (const i in data) {
            const u = data[i];
            let blob = u.slice(0, u.size, u.type)
            let filename = u.name
            files.append(i, new File([ blob ], filename, { ...u, type: u.type }));
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        return axios.post(`/api/v3${ url }`, files, config);
    };

    /**
     * post given data to url with file
     */
    fileMulti = async (url, data, report) => {

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        for(const u of data) {
            const file = new FormData();
            let blob = u.slice(0, file.size, file.type)
            let filename = u.name
            file.append(filename, new File([blob], filename, { ...u }));
            const response = await axios.post(`/api/v3${ url }`, file, config);
            console.log("RE", response);
        }
    };

    /**
     * post given data to url with file
     */
    updateWithFile = (url, data) => {
        const formData = new FormData();
        for (const k in data) {
            formData.append(k, data[k]);
        }

        const config = {
            headers: {
                ...axios.defaults.headers,
                'content-type': 'multipart/form-data',
            },
        };
        return axios.patch(`/api/v3${ url }`, formData, config);
    };
}

/*
Check if token available in session
*/
let user = getUserFromSession();
if (user) {
    const { token } = user;
    if (token) {
        setAuthorization(token);
    }
}

export { APICore, setAuthorization };
