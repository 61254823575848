import React, {useEffect, useState} from 'react'
// import Award from "../../../../Common/lib/Award/Award"

export const NomineeList = ({ award, cast, type, hasNominated }) => {

    const [ castList, setCastList ] = useState([] )

    useEffect( () => {
        let subscribed = true
        if (subscribed && cast) {
            let sortedCast = cast.sort(dynamicSort('cast_award_map_id'))
            setCastList(sortedCast)
        }
        return () => subscribed = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ cast ])

    const dynamicSort = (property) => {
        let sortOrder = 1;
        if(property[0] === "-") {
            sortOrder = -1;
            property = property.substring(1);
        }
        return function (a,b) {
            let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }

    return (
        <div className="nomineelist__nominee--people" style={{marginTop:'.25rem'}}>
            { castList && Object.keys(castList).length>0 ?
                castList.map( (c, i) => {
                 return (
                      <React.Fragment key={ i }>
                          <span className="nomineelist__nominee--person">{i===0?'':', '}{ c.name }</span>
                      </React.Fragment>
                  )
                })
            :
                <React.Fragment>
                    <div className="nomineelist__nominee--person mt-2">{ award.name }
                        {/*
                            award.roleName ?
                                <span className="text-uppercase font-weight-light ps-2" style={{
                                    fontSize:'.6rem'
                                }}>as {award.roleName}</span>
                                : ""
                        */}
                        {
                            award.winner ?
                                <div className="label-small text-red winner-label d-flex mt-0">
                                    <div className="border-bottom flex-fill ms-4 line-small" />
                                    <div className="px-2">WINNER</div>
                                    <div className="border-bottom flex-fill me-4 line-small" />
                                </div>
                                :""
                        }
                        {
                            award.honoree ?
                                <div className="label-small text-red winner-label d-flex mt-0">
                                    <div className="border-bottom flex-fill ms-4 line-small" />
                                    <div className="px-2">HONOREE</div>
                                    <div className="border-bottom flex-fill me-4 line-small" />
                                </div>
                                :""
                        }

                    </div>

                </React.Fragment>

            }
        </div>
    )
}
