import React, { useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'

export const GuestForm = ({ idx, updateGuest }) => {
    const [ name, _setName ] = useState( "" ),
        [ showWarningModal, setShowWarningModal ] = useState( false )
    const setName = (val) => {
        _setName(val)
        updateGuest(idx, { name: name }, false)
    }
    const handleChange = (val) => {
        setName(val)
    }
    const removeGuest = () => {
        setName("")
        updateGuest(idx, { name: name }, true)
    }
    return (
        <div className="row">
            <div className="col position-relative">
                <Form.Group controlId='guest_name'>
                    <Form.Control value={ name }
                                  required
                                  style={{ opacity:1, borderBottom: "1px solid #666" }}
                                  onChange={ (e) => {
                                      handleChange(e.target.value)
                                  }}
                                  onBlur={ (e) => {
                                      handleChange(e.target.value)
                                  }}
                                  placeholder='Guest Name' />
                </Form.Group>

                {(name==='')?(
                    <div className="validation__message">Please enter the guest name</div>
                ):("")}

                {
                    (name || name==='') ?
                        <div style={{
                            position:'absolute',
                            top:5,
                            right:'1.1rem'
                        }}>
                            <Button className="button__outline " style={{
                                fontSize:'.6rem',
                                border:0
                            }} onClick={()=>{
                                setShowWarningModal(true)
                            }}>Remove Guest</Button>
                        </div>
                        : null
                }

            </div>

            <Modal show={ showWarningModal } onHide={()=>{setShowWarningModal(false)}} size="lg" dialogClassName="event__modal-new eventmodal__container" centered={true}>
                <Modal.Body>
                    <div className="my-3 text-center font-weight- bold">
                        Are you sure you want to remove this guest?
                    </div>
                    <div className="justify-content-center mb-2 d-flex">
                        <Button className="button__box px-4"
                                onClick={ (e) =>{
                                    setShowWarningModal(false)
                                    removeGuest()
                                } }>
                            Yes
                        </Button>
                        <Button className="ml-2 button__box button__grey px-4"
                                onClick={ (e) =>{
                                    setShowWarningModal(false)
                                } }>
                            No
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}