import React from 'react'
// import { useRouteMatch } from 'react-router-dom'
// import { JewelCtx } from '../../../Common/Jewel'
import Competition from './Competition'
import sidebarShadow from '../../../Common/_assets/img/placeholder/sidebar-shadow.png'

const CompetitionsAwardsColumns = ({competitions, titleName, show_id, slug, award_count}) => {

    // const match = useRouteMatch()

    // const { Show } = useContext(JewelCtx)

    // const [ competitions, setCompetitions ] = useState( [] )
    //
    // useEffect( () => {
    //     let subscribed = true
    //     if (subscribed)
    //         // Show().Award.getShowCompetitions(slug, result => {
    //         //     setCompetitions(result)
    //         // })
    //         setCompetitions([])
    //     return () => subscribed = false
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [ slug ])

    return (

      <>



      <div className="d-flex mb-5 pb-5 flex-md-fill flex-column align-items-center">
          <div className="sidebar__container--nomineelist-wrapper nomination__columns flex-md-fill row col-12 col-md-8">

              <div className="d-none font-weight-light text-uppercase mt-2 mb-4 text-center "
                   style={{fontSize: '.7rem', letterSpacing: '.3rem'}}>For Your Consideration
              </div>
              <div className="mt-4 mb-5 text-center">
                  <div className="font-weight-light text-uppercase text-center mx-auto d-inline-block" style={{
                      // background:"rgba(255,255,255,.15)",
                      borderRadius: "7px",
                      fontSize: '.7rem',
                      letterSpacing: '.3rem'
                  }}>
                      <div className={'mb-3 text-red'}
                           style={{fontSize: '2.5rem', fontWeight: '300', letterSpacing: '0rem'}}>{award_count}</div>
                      <div>Emmy&reg; Nomination{award_count>1 ? 's' : ''}</div>
                  </div>
              </div>

              {
                  competitions.map((competition, i) => {
                      return (
                          <div key={i} className="col-12 mt-3 d-flex justify-content-center">
                              <div className="" style={{
                                  display: 'flex',
                                  flexDirection: ' column',
                                  justifyContent: 'center'
                              }}>
                                  <Competition competition={competition} slug={slug} award_count={award_count}/>
                              </div>
                          </div>
                      )
                  })
              }

              <div className="sidebar__container--shadow d-none"><img src={sidebarShadow} className="" alt="Netflix"/>
              </div>

          </div>
      </div>


      </>
    )
}

export default CompetitionsAwardsColumns
