import { APICore } from "../apiCore";

const api = new APICore();

function fetchRsvp(refHash) {
    return api.get(`/updatersvp/${ refHash }`);
}

function cancelRsvp(refHash) {
    return api.delete(`/updatersvp/${ refHash }`);
}

function createRsvp(slug, rsvp) {
    return api.create(`/rsvp/${ slug }`, { rsvp });
}

function reviseRsvp(refHash, rsvp) {
    return api.update(`/updatersvp/${ refHash }`, { rsvp });
}

export { cancelRsvp, createRsvp, fetchRsvp, reviseRsvp }
