import React, {useEffect, useState} from "react";
import netflixLogo from '../../Common/_assets/img/logo/netflix-logo-ribbon.png'

const Header = ({setShowSearch,
                isLoggedIn,
                displayPinModal,
                setDisplayPinModal,
                setDisplayScreenerProjectList,
                    setActiveProjectKey,
                    activeTitleName,
                    setActiveTitleName,
                    indeeApiChecked
}) => {

  const selectedType = window.location.pathname.includes('pga') ? "pga" : window.location.pathname.includes('ves') ? "ves" : "";

  const [pageScrolled, setPageScrolled] = useState(false);


    /**SCROLL LISTENER**/
    useEffect(() => {
        if (window) {
            window.addEventListener("scroll", function () {
                if (window.pageYOffset > 7) {
                    setPageScrolled(true)
                } else {
                    setPageScrolled(false)
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /**END SCROLL LISTENER**/

  return (
    <header className={"header__container"} style={{
        backgroundColor:pageScrolled?'rgba(0,0,0,.5)':'transparent'
    }}>

      <div className="header__sticky d-flex">
        <div className="width_ctrl d-flex align-items-center px-3 px-md-0">

          <div className=" header__logo-wrapper ms-0 ms-md-3 me-2 me-md-3">
            <a href={`/${selectedType ? selectedType : ""}`}><img src={netflixLogo} className="header__logo" alt="Netflix" /></a>
          </div>

          <div className=" header__title d-flex flex-row justify-content-start">
            <a href={`/${selectedType || ""}`}>
              <span className="header__title--main">Series</span>
              <span className="header__title--tagline d-none d-md-block">For Your Consideration</span>
              <span className="header__title--tagline d-block d-md-none">FYC</span>
            </a>
          </div>

          <div className="d-flex header__buttons-container flex-row align-items-center justify-content-end ms-auto">
              <div className="d-flex header__buttons header__buttons--desktop justify-content-end ms-auto pe-0 pe-md-3">
                  <button className=" p-0 " onClick={() => {
                      setShowSearch(true)
                  }}><span className="button__text">
                <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none"
                     strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><circle cx="11" cy="11"
                                                                                                 r="8"></circle><line
                    x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                </span>
                  </button>


                  <a href={`/${selectedType || ""}`} onClick={(e) => {
                      e.preventDefault()
                  }} className={"screenerLink d-flex ms-2 ms-md-4 align-items-center "+(!indeeApiChecked?'screenerLinkLoading':'')} style={{
                      opacity:indeeApiChecked?1:.3,
                      pointerEvents:indeeApiChecked?'all':'none'
                  }}>
                      <button className="p-0 " onClick={() => {
                          if (isLoggedIn) {
                              // console.log('header screeners', activeTitleName)
                              setActiveProjectKey(null)
                              setActiveTitleName(null)
                              setDisplayScreenerProjectList(true)
                          } else {
                              setDisplayPinModal(true)
                          }

                      }}>
                          <span className="button__text ">
                            SCREENERS
                          </span>
                      </button>
                  </a>

                  <a href={'/events'}
                     className="d-flex ms-2 ms-md-4 align-items-center">
                      <button className="p-0 "><span className="button__text ">
                  EVENTS
                  </span>
                      </button>
                  </a>


              </div>
          </div>

        </div>
      </div>

    </header>
  )
}

export default Header
