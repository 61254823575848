import React, {useEffect, useState} from 'react'
import Moment from 'react-moment'
import socialIconInstagram from '../../../Common/_assets/img/icon/iconsimple-ig.png'
import socialIconTwitter from '../../../Common/_assets/img/icon/iconsimple-x.png'
import socialIconFacebook from '../../../Common/_assets/img/icon/iconsimple-fb.png'
import SagIneligible from "./SagIneligible";

const SidebarMeta = ({titleName,
                        socialFB,
                        socialTW,
                        socialIG,
                        reviewQuote,
                        reviewCredit,
                        indeeProjectKey,
                        setActiveProjectKey,
                        isLoggedIn,
                        setDisplayPinModal,
                        setDisplayScreenerProjectList,
                        setActiveTitleName,
                        sagIneligible,
                        hasAwards,
                        availableOn,
                        indeeApiChecked
                }) => {

    const [releaseDate, setReleaseDate] = useState(null),
        [releaseDateHasPassed, setReleaseDateHasPassed] = useState(false)

    useEffect( () => {
      let subscribed = true;
      if (subscribed && availableOn) {
        Moment.globalLocale = 'en-us'
          let tempDate = new Date(availableOn)
          tempDate.setDate(tempDate.getDate()+1)
          tempDate.setHours(0, 0, 0, 0)
          setReleaseDate(tempDate)
          setReleaseDateHasPassed(dateHasPassed(availableOn))
      }
      return ()=>{subscribed=false}
    }, [availableOn])

    const dateHasPassed = (titleReleaseDate) => {
        const nowDate = new Date();
        let compareDate = new Date(titleReleaseDate)
        compareDate.setDate(compareDate.getDate()+1)
        compareDate.setHours(0, 0, 0, 0)
        // console.log('dateHasPassed',nowDate, compareDate, nowDate >= compareDate)
        return nowDate >= compareDate;
    }

    if (titleName) {
        return (


            <div className={'w-100'}>

                {
                    reviewQuote ?

                        <div className="text-center pt-4 mt-0 mt-md-0 pb-4 "
                             style={{letterSpacing: '0rem', lineHeight: '1.35em', fontSize: '1.15rem'}}>
                            {reviewCredit ? '"' : ''}{reviewQuote}{reviewCredit ? '"' : ''}
                            {
                                reviewCredit ?
                                    <div className="text-small font-weight-bold">~ {reviewCredit}</div>
                                    : null
                            }

                        </div>

                        : ""
                }

                <div className="d-flex titlepage__button--container py-3 justify-content-center align-items-center">

                                {
                                    (releaseDate && releaseDateHasPassed && indeeProjectKey) ?
                                        <div className={'col-12 col-md-4 mx-auto screenerButton '+(!indeeApiChecked?'screenerButtonLoading':'')}>
                                        <button onClick={() => {
                                            console.log('isloggedin', isLoggedIn, indeeProjectKey)
                                            setActiveProjectKey(indeeProjectKey)
                                            setActiveTitleName(titleName)
                                            if (isLoggedIn) {
                                                setDisplayScreenerProjectList(true)
                                            } else {
                                                setDisplayPinModal(true)
                                            }
                                        }} className={" button__box w-100 justify-content-center "}
                                        style={{
                                            opacity:indeeApiChecked?1:.3,
                                            pointerEvents:indeeApiChecked?'all':'none'
                                        }}>Watch
                                            Now</button>
                                        </div>
                                        : (releaseDate && !releaseDateHasPassed) ?
                                            <span className={'py-4'} style={{
                                                fontSize: '.8rem',
                                                textTransform: 'uppercase',
                                                letterSpacing: '.2rem'
                                            }}>Available <Moment format="MMMM DD, YYYY">{releaseDate}</Moment></span>
                                        :
                                            <span className={'py-4'} style={{
                                                fontSize: '.8rem',
                                                textTransform: 'uppercase',
                                                letterSpacing: '.2rem'
                                            }}>Coming Soon ...</span>
                                }

                </div>


                {(socialFB || socialIG || socialTW) ? (
                    <div className="titlepage__social--container pb-3  justify-content-center align-items-center ">
                        <div className="w-100 d-flex justify-content-center align-items-center">

                            {(socialTW) ? (
                                <div className="p-2"><a href={socialTW} target="_blank" rel="noopener noreferrer"><img
                                    className="social-icon mx-1" src={socialIconTwitter} alt="Netflix"/></a></div>
                            ) : ("")}

                            {(socialIG) ? (
                                <div className="p-2"><a href={socialIG} target="_blank" rel="noopener noreferrer"><img
                                    className="social-icon mx-1" src={socialIconInstagram} alt="Netflix"/></a></div>
                            ) : ("")}

                            {(socialFB) ? (
                                <div className="p-2"><a href={socialFB} target="_blank" rel="noopener noreferrer"><img
                                    className="mx-1 social-icon" src={socialIconFacebook} alt="Netflix"/></a></div>
                            ) : ("")}


                        </div>
                    </div>
                ) : ("")}

                <SagIneligible show={!hasAwards && sagIneligible}/>


            </div>

        )
    } else return null

}

export default SidebarMeta
