import { APICore } from "../apiCore";

const api = new APICore();

function createSubscription({ name, email, guildId }) {
    return api.create(`/subscription`, { name, email, guildId });
}

function unsubscribe({ refHash }) {
    return api.update(`/unsubscribe/${ refHash }`, {});
}

export { createSubscription, unsubscribe };
