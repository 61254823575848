import React, {useEffect, useState} from 'react'
// import React, { useLayoutEffect, useState } from 'react'
// import Event from "../../Common/lib/Event/Event";
import EventExcerpt from './EventExcerpt'

import {listEvents} from "../../utils/api/helpers/event";

const EventList = () => {

    const [eventList, setEventList] = useState(null)

    useEffect( () => {
        let subscribed = true
        if (subscribed) {
            listEvents()
                .then(result => {
                    console.log("events", result.data)
                    if (result.data) {
                        setEventList(result.data)
                    }
                })
        }
        return () => subscribed = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (

    <>
      <div className="width_ctrl">
          <div className="events__grid pt-0 pt-md-5 mb-5" style={{marginTop: 75}}>

              <h1 className="events__header text-center">EVENTS</h1>
              <div
                  className="events__grid--inner d-flex flex-column flex-md-row flex-md-wrap justify-content-center">


                  {eventList && Object.keys(eventList).length ?
                      eventList.map((o, i) => {
                          return <EventExcerpt key={i} event_object={o}/>
                      })
                      :
                      <div className="text-center "></div>
                  }


              </div>

          </div>
      </div>
        <div className="main__background events__background" />
    </>

    )
}

export default EventList
