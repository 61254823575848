import React, { memo, useLayoutEffect, useState} from 'react'
// import Award from "../../../Common/lib/Award/Award"
import {NomineeList} from "./Awards/NomineeList";
import  $ from 'jquery'

const Competition = memo(({ competition, slug, award_count }) => {

    const [ programAwards, setProgramAwards ] = useState([] ),
        [ individualAwards, setIndividualAwards ] = useState([] ),
        [ groupAwards, setGroupAwards ] = useState([] ),
          [ hasNominated, setHasNominated ] = useState(false )


    useLayoutEffect( () => {
        let subscribed = true
        if (subscribed) {
            setProgramAwards(competition.program)
            setGroupAwards(competition.group)
            setIndividualAwards(competition.individual)
            setHasNominated(false)
        }
        return () => subscribed = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ competition, slug ])


    useLayoutEffect( () => {
        let subscribed = true
        if (subscribed) {
            var duplicateIds = individualAwards
                .map(e => e['award_id'])
                .map((e, i, final) => final.indexOf(e) !== i && i)
                .filter(obj=> individualAwards[obj])
                .map(e => individualAwards[e]["award_id"]);
            $(duplicateIds).each((i,o)=>{
                $('.sidebar__container--nomineelist-ul > li.awardid_'+o).removeClass('mb-4')
                $('.sidebar__container--nomineelist-ul > li.awardid_'+o+':last-of-type').addClass('mb-4')
                $('.sidebar__container--nomineelist-ul > li.awardid_'+o+' ~ li.awardid_'+o+' .nomineelist__nominee--award').css({'display':'none'})
                $('.sidebar__container--nomineelist-ul > li.awardid_'+o+' .nomineelist__nominee--award').addClass('mt-4')
                // $('.sidebar__container--nomineelist-ul > li.awardid_'+o+' ~ li.awardid_'+o+' .nomineelist__nominee--title').css({'margin-top':'1.25rem'})
            })

            $('.sidebar__container--nomineelist-ul > li').each((i,o)=>{
                if ($(this).find('.nomineelist__nominee--people').html()==='') {
                    $(this).find('.nomineelist__nominee--people').css({'display':'none'})
                }
            })
        }
        return () => subscribed = false
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ individualAwards ])


    return (
        <div className="sidebar__container--nomineelist d-flex flex-column justify-content-start mb-2">

            <div className="d-none mt-2 mb-4 px-0 px-md-2 text-center font-weight-bold">
                <span className={competition.name}>
                      {competition.name}
                </span>
            </div>

            <ul className="sidebar__container--nomineelist-ul pb-0">
                {
                    programAwards && Object.keys(programAwards).length > 0 ?
                        <>
                            {programAwards.map((a, i) => {
                                return (
                                    <li key={i} className={"program-awards-list-item px-0 px-md-5 mb-4 awardid_"}>
                                        <div className="nomineelist__nominee text-center mb-2">
                                            <div
                                                className={"nomineelist__nominee--award mb-2 awardid_"}>{a.awardName}</div>
                                            {
                                                a.winner ?
                                                    <div className="label-small text-red winner-label d-flex mt-0">
                                                        <div className="border-bottom flex-fill ms-4 line-small"/>
                                                        <div className="px-2">WINNER</div>
                                                        <div className="border-bottom flex-fill me-4 line-small"/>
                                                    </div>
                                                    : ""
                                            }
                                            {
                                                a.honoree ?
                                                    <div className="label-small winner-label d-flex mt-0">
                                                        <div className="border-bottom flex-fill ms-4 line-small"/>
                                                        <div className="px-2">HONOREE</div>
                                                        <div className="border-bottom flex-fill me-4 line-small"/>
                                                    </div>
                                                    : ""
                                            }
                                        </div>
                                    </li>
                                )
                            })}
                        </>
                        : null
                }
            </ul>
            <ul className="sidebar__container--nomineelist-ul pb-0">
                {
                    individualAwards && Object.keys(individualAwards).length > 0 ?
                        <>
                            {individualAwards.map((a, i) => {
                                return (
                                    <li key={i}
                                        className={"individual-awards-list-item px-0 px-md-5 " + (i < (individualAwards.length - 1) ? "mb-4" : "") + " awardid_" + a.award_id}>
                                        <div className="nomineelist__nominee text-center mb-2">
                                            <div
                                                className={"nomineelist__nominee--award mb-2 awardid_"}>{a.awardName}</div>
                                            <NomineeList type={'INDIVIDUAL'} award={a} cast={a.cast}
                                                         hasNominated={hasNominated}/>
                                        </div>
                                    </li>
                                )
                            })}
                        </>
                        : null
                }
            </ul>
            <ul className="sidebar__container--nomineelist-ul pb-0">
                {
                    groupAwards && Object.keys(groupAwards).length > 0 ?
                        <>
                            {
                                groupAwards.map((a, i) => {
                                    return (
                                        <li key={i} className={"group-awards-list-item px-0 px-md-5 mb-4 awardid_"}>
                                            <div className="nomineelist__nominee text-center mb-2">
                                                <div
                                                    className={"nomineelist__nominee--award mt-0 awardid_"}>{a.awardName}</div>

                                                {
                                                    a.title ?
                                                        <div className="nomineelist__nominee--title" style={{
                                                            marginTop: '.5rem',
                                                            fontWeight: 'bold',
                                                            lineHeight: '1.35em'
                                                        }}>{a.title}</div>
                                                        : null
                                                }

                                                <NomineeList type={'GROUP'} award={a} cast={a.cast}
                                                             hasNominated={hasNominated}/>

                                                {
                                                    a.winner ?
                                                        <div className="label-small text-red winner-label d-flex mt-0">
                                                            <div className="border-bottom flex-fill ms-4 line-small"/>
                                                            <div className="px-2">WINNER</div>
                                                            <div className="border-bottom flex-fill me-4 line-small"/>
                                                        </div>
                                                        : ""
                                                }
                                                {
                                                    a.honoree ?
                                                        <div className="label-small winner-label d-flex mt-0">
                                                            <div className="border-bottom flex-fill ms-4 line-small"/>
                                                            <div className="px-2">HONOREE</div>
                                                            <div className="border-bottom flex-fill me-4 line-small"/>
                                                        </div>
                                                        : ""
                                                }

                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </>
                        : null
                }

            </ul>

        </div>
    )
})

export default Competition
