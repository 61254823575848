import { APICore } from "../apiCore";

const api = new APICore();

function listVideosByShowSlug(slug) {
    return api.get(`/show/${ slug }/video`);
}

function getShowVideoBySlug(showSlug, videoSlug) {
    return api.get(`/show/${ showSlug }/video/${videoSlug}`);
}

function getVideoBySlug(videoSlug) {
    return api.get(`/video/${ videoSlug }`);
}

function listVideos() {
    return api.get('/video');
}

function listVideosByCraftSlug(craftSlug) {
    return api.get(`/video/craft/${ craftSlug }`);
}

function listVideosByPlaylistSlug(playlistSlug) {
    return api.get(`/video/playlist/${ playlistSlug }`);
}

export { listVideosByShowSlug, getVideoBySlug, getShowVideoBySlug, listVideos, listVideosByCraftSlug, listVideosByPlaylistSlug };
