import React, { useLayoutEffect } from 'react'
import $ from 'jquery'
import VisibleCategories from '../../../utils/visibleCategories'
import SidebarCategory from './SidebarCategory'
// import EventSignUp from "../EventSignUp/EventSignUp";

const Mobile = ({categories, videoPlaying, playVideo, pauseVideo}) => {

    useLayoutEffect( () => {
      let subscribed = true;
      if (subscribed) {

        $(document).on('click', '.sidebar__container--mobile li > a', (e)=>{
          e.preventDefault()

            $('.sidebar__list--flyout').hide()
            $(e.target).next('.sidebar__list--flyout').show()

        })
      }
      return ()=>{subscribed=false}
    }, [])

    return (


        <div className="sidebar__container--inner d-flex flex-column ">

            <ul className="pt-5 sidebar__container--list  flex-fill d-flex flex-column justify-content-center">

                {
                    categories ?
                        <>
                            {
                                categories.map((c, i) => {
                                    if (VisibleCategories.includes(c.slug)) {
                                        return <SidebarCategory key={i} category={c}/>
                                    } else {
                                        return null
                                    }
                                })
                            }
                        </>
                        : ""
                }


            </ul>


        </div>

    )
}

export default Mobile
