import React, {useEffect, useState} from 'react'
import thumbPlaceholder from '../../Common/_assets/img/placeholder/video-placeholder-n.jpg'
import {prepareProjectScreener} from "../../utils/api/helpers/indee";

const ScreenerGridItem = ({ projectName, projectKey, scr, setOpenScreenerModal, setActiveScreener }) => {

    const [title, setTitle] = useState(null),
        [description, setDescription] = useState(null),
        [episodeNumber, setEpisodeNumber] = useState(null);

    useEffect( () => {
        let subscribed=true
        if (subscribed && scr) {
            // console.log(scr)
            setDescription(scr.description ? truncate(scr.description) : '')
            setTitle(scr.name)
            setEpisodeNumber(scr.episode)
        }
        return ()=>{subscribed=false}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scr])

    const truncate = (input) => {
        if (input.length > 5) {
            return input.substring(0, 130) + '...';
        }
        return input;
    };

    const prepareScreener = () => {
        prepareProjectScreener(projectKey, scr.key)
            .then(result => {
                scr.screening_details.screener_key = result.data.screening_details.screener_key;
                setActiveScreener(scr);
                setOpenScreenerModal(true);
            });
    }

    return (
        <div onClick={() => {
            prepareScreener();
        }} className={'episodegrid__item-container titledetail__mediaitem col-12 col-md-4 mb-3'}
             style={{cursor: 'pointer'}}>
            <img src={scr.poster ? scr.poster : thumbPlaceholder} alt={title}/>
            <div
                className={'episodegrid__item-title'}>{episodeNumber ? 'Episode ' + episodeNumber + ' - ' + title : projectName + ' - ' + title}</div>
            <div className={'episodegrid__item-description'}>{description}</div>

        </div>
    )
}

export default ScreenerGridItem;
