import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'

import netflixLogo from '../../Common/_assets/img/logo/netflix-logo-ribbon.png'
import iconInstagram from '../../Common/_assets/img/icon/icon_instagram.svg'
import iconTwitter from '../../Common/_assets/img/icon/icon_twitter.svg'

const Footer = (props) => {
    return (
      <footer className="footer__container mt-auto pt-4">

        <Row className="footer__content px-3 width_ctrl " nogutters="true">
          <Col className="d-flex align-items-center">
            <Link to="/" ><img src={netflixLogo} className="footer__logo me-3" alt="Netflix" /></Link>
            <Link to="/" className="footer__title">
                <span className="footer__title--main">Series</span>
                <span className="footer__title--tagline d-none d-md-block">For Your Consideration</span>
                <span className="footer__title--tagline d-block d-md-none">FYC</span>
            </Link>
          </Col>
          <Col className="d-none justify-content-end align-items-center footer__social">
            <a href="https://www.instagram.com/netflixqueue/" className="footer__social-icon" rel="noopener noreferrer" target="_blank"><img src={iconInstagram} alt="Instagram" /></a>
            <a href="https://twitter.com/NetflixQueue" className="footer__social-icon" rel="noopener noreferrer" target="_blank"><img src={iconTwitter} alt="Twitter" /></a>
          </Col>
        </Row>
        <Row className="footer__content width_ctrl justify-content-center" nogutters="true">
          <Col className="footer__links d-flex justify-content-center align-items-center col-12">
            <a href="https://netflix.com" rel="noopener noreferrer" target="_blank">Netflix.com</a>
            <Link to="/privacy">Privacy</Link>
          </Col>
          <Col className="footer__copyright d-flex justify-content-center align-items-center">
            <p>All uses of the Netflix For Your Consideration Website are subject to the terms of use associated with your promotional code and the <a href="/privacy">Privacy Statement</a></p>
          </Col>
        </Row>
      </footer>
    )
}

export default Footer
