import React from 'react'
// import { useRouteMatch } from 'react-router-dom'
// import { JewelCtx } from '../../../Common/Jewel'
import Competition from './Competition'
import sidebarShadow from '../../../Common/_assets/img/placeholder/sidebar-shadow.png'

const CompetitionsAwards = ({competitions, titleName, show_id, slug, award_count}) => {



    return (

        <>
            <div className="font-weight-light text-uppercase text-center " style={{
                opacity:.5,
                fontSize:'.7rem',
                letterSpacing:'.3rem',
                marginTop:'2rem',
                marginBottom:'2rem'
            }}>
                For Your Consideration
            </div>
            <div className="d-none mt-4 mb-5 text-center">
                <div className="font-weight-light text-uppercase text-center mx-auto d-inline-block" style={{
                    // background:"rgba(255,255,255,.15)",
                    borderRadius:"7px",
                    fontSize:'.7rem',
                    letterSpacing:'.3rem'
                }}>
                    <div className={'mb-3 text-red'} style={{fontSize:'2.5rem',fontWeight:'300',letterSpacing:'0rem'}}>{award_count}</div>
                    <div>Emmy&reg; Nomination{award_count>1 ? 's' : ''}</div>
                </div>
            </div>
            { competitions ?
                competitions.map( (competition, i) => {
                    return (
                        <React.Fragment key={ i }>
                            <div className="sidebar__container--nomineelist-wrapper nomination__sidebar flex-md-fill d-flex flex-column">
                                <Competition competition={ competition } slug={ slug } award_count={award_count} />
                            </div>
                        </React.Fragment>
                    )
                })
                :null
            }

            <div className="sidebar__container--shadow d-none"><img src={sidebarShadow} className="" alt="Netflix" /></div>

        </>
    )
}

export default CompetitionsAwards
