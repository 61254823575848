import React, {useEffect, useState} from 'react'
import MediaItem from './MediaItem'

const MediaGrid = ({titleSlug, categorySlug, mediaLists, mediaSection, gridTitle}) => {

    const [mediaList, setMediaList] = useState(null)

    useEffect( () => {
        let subscribed=true
        if (subscribed && mediaLists) {
            // console.log(mediaLists)
            let tempArr = mediaLists.filter( el => {
                return el !== null
            });
            if (tempArr.length > 1) {
                setMediaList(flatten(tempArr))
            } else {
                setMediaList(tempArr[0])
            }
        }
        return ()=>{subscribed=false}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ mediaLists ])

    const flatten = (arr) => {
        return [].concat(...arr);
    }

    return (

        <div className={"titledetail__feed-container"}>
            <div className={"titledetail__feed-title"}>
                <span className={'line'}/>
                <span className={'title'}>{gridTitle}</span>
                <span className={'line'}/>
            </div>
            <div className={"d-flex flex-wrap no-gutters justify-content-start"}>
            { mediaList ?
                mediaList.map((m, i) =>
                    <MediaItem
                        key={i}
                        mediaItem={m}
                        mediaSection={mediaSection}
                        categorySlug={categorySlug}
                        titleSlug={titleSlug}
                    />
                )
            : null
            }
            </div>
        </div>
    )
}

export default MediaGrid
