import React from 'react'
import { isMobile, isTablet } from "react-device-detect"
import Desktop from './Desktop'
import Mobile from './Mobile'

const Sidebar = ({categories, videoPlaying, playVideo, pauseVideo}) => {

if (isMobile || isTablet) {
      return (
        <div className="col-12 sidebar__container sidebar__container--mobile ">
          <Mobile categories={categories} videoPlaying={videoPlaying} playVideo={playVideo} pauseVideo={pauseVideo} />
        </div>
      )
  } else {
    return (
      <div className="col-2 sidebar__container sidebar__container--desktop">
        <Desktop categories={categories} videoPlaying={videoPlaying} playVideo={playVideo} pauseVideo={pauseVideo} />
      </div>
    )
  }

}

export default Sidebar
