import { APICore } from "../apiCore";

const api = new APICore();

function getShowAwards(genreSlug, showSlug) {
    return api.get(`/genre/${ genreSlug }/show/${ showSlug }/award`)
}

function getShowDetailPageData(genreSlug, showSlug) {
    return api.get(`/genre/${ genreSlug }/show/${ showSlug }`);
}

export { getShowAwards, getShowDetailPageData };
