import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import IndeePlayer from "./IndeePlayer";

const ScreenerModal = ({projectName, openScreenerModal, setOpenScreenerModal, activeKey, activeScreener, setActiveScreener, screenerList}) => {

    const [key, setKey] = useState(null),
        [title, setTitle] = useState(null),
        [episodeNumber, setEpisodeNumber] = useState(null),
        [viewList, setViewList] = useState(false)

    useEffect( () => {
        let subscribed=true
        if (subscribed && activeScreener) {
            // console.log(activeScreener.screening_details)
            setKey(activeScreener.screening_details.screener_key)
            setTitle(activeScreener.name)
            setEpisodeNumber(activeScreener.episode)
        }
        return ()=>{subscribed=false}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeScreener])

    return (
        <Modal fullscreen={true} show={ openScreenerModal } onHide={ () => setOpenScreenerModal(false) }>
            <Modal.Header closeButton>
                <Modal.Title>
                    <span className={'d-inline-block me-3'} style={{
                        cursor: 'pointer',
                        fontSize: '.7rem',
                        textTransform: 'uppercase'
                    }} onClick={() => {
                        setOpenScreenerModal(false)
                    }}>
                        <svg viewBox="0 0 24 24" width="16" height="16" stroke="currentColor" strokeWidth="2"
                             fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><line
                            x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg>
                    </span>
                    <span
                        className={'me-4'}>{projectName} - {episodeNumber ? 'Episode ' + episodeNumber + ' -' : ''} {title}</span>
                    <span className={'d-inline-block'} style={{
                        cursor: 'pointer',
                        fontSize: '.7rem',
                        textTransform: 'uppercase'
                    }} onClick={() => {
                        setViewList(!viewList)
                    }}>
                        <svg viewBox="0 0 24 24" width="16" height="16"
                             stroke="currentColor" strokeWidth="2" fill="none"
                             strokeLinecap="round" strokeLinejoin="round"
                             className="css-i6dzq1"><line x1="8" y1="6" x2="21"
                                                          y2="6"></line><line x1="8" y1="12"
                                                                              x2="21"
                                                                              y2="12"></line><line
                            x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line
                            x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
                        <span className={'ps-2'}>{viewList ? 'Hide' : 'View'} Episode List</span>
                    </span>
                    <span className={'flex-fill'} />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <IndeePlayer screenerKey={key}
                             screenerList={screenerList}
                             projectKey={key}
                             projectName={projectName}
                             activeScreener={activeScreener}
                             activeKey={activeKey}
                             setActiveScreener={setActiveScreener}
                             viewList={viewList}
                />
            </Modal.Body>
        </Modal>
    );
}

export default ScreenerModal;
