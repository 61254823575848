import React, { useEffect, useState } from 'react'
import { Form, Button, Modal } from 'react-bootstrap'
// import Rsvp from "../../Common/lib/Rsvp/Rsvp";

export const ReviseGuestForm = ({ idx, updateGuest, guest, formIsValid }) => {

    const [ name, _setName ] = useState( "" ),
            [ peopleId, _setPeopleId ] = useState( 0 ),
          [ showWarningModal, setShowWarningModal ] = useState( false )

    // const setName = (val) => {
    //     _setName(val)
    //     updateGuest(idx, { people_id: peopleId, name: name }, false)
    // }

    useEffect( () => {
        let subscribed = true
        if (subscribed) {
            if (guest) {
                _setName(guest.name)
                _setPeopleId(guest.people_id)
            } else {
                //
            }
        }
        return ()=>{subscribed = false}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ guest ])

    const handleChange = (val) => {
        _setName(val)
        if (guest) {
            updateGuest(idx, { people_id: peopleId, name: val }, false)
        }

    }

    const removeGuest = () => {
        if (guest) {
            updateGuest(idx, {people_id: peopleId, name: name}, true)
        }
            _setName("")
            _setPeopleId(0)

    }

    return (
        <div className="row">
            <div className="col position-relative">
                <Form.Group controlId='guest_name'>
                    <Form.Control value={ name }
                                  required
                                  style={{ opacity:1, borderBottom: "1px solid #666" }}
                                  onChange={ (e) => {
                                      handleChange(e.target.value)
                                  }}
                                  onBlur={ (e) => {
                                      handleChange(e.target.value)

                                  }}
                                  placeholder='Guest Name' />
                </Form.Group>

                {(name==='')?(
                    <div className="validation__message">Please enter the guest name</div>
                ):("")}

                {
                  (name || name==='') ?
                    <div style={{
                      position:'absolute',
                      top:5,
                      right:'1.1rem'
                    }}>
                      <Button className="button__outline " style={{
                        fontSize:'.6rem',
                        border:0
                      }} onClick={()=>{
                        setShowWarningModal(true)
                      }}>Remove Guest</Button>
                    </div>
                  : null
                }

            </div>

            <Modal show={ showWarningModal } onHide={()=>{setShowWarningModal(false)}} size="lg" dialogClassName="event__modal-new eventmodal__container" centered={true}>
                <Modal.Body>
                    <div className="my-3 text-center font-weight- bold">
                      Are you sure you want to remove this guest?
                    </div>
                    <div className="justify-content-center mb-2 d-flex">
                      <Button className="button__box px-4"
                              onClick={ (e) =>{
                                  setShowWarningModal(false)
                                  removeGuest()
                              } }>
                          Yes
                      </Button>
                      <Button className="ml-2 button__box button__grey px-4"
                              onClick={ (e) =>{
                                  setShowWarningModal(false)
                              } }>
                          No
                      </Button>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}
