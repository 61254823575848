import React, {useEffect, useState} from 'react'
import thumbPlaceholder from '../../Common/_assets/img/placeholder/video-placeholder-n.jpg'
import {prepareProjectScreener} from "../../utils/api/helpers/indee";

const ScreenerListItem = ({ projectName, scr, setLoaded, projectKey, activeKey, activeScreener, setActiveScreener }) => {

    const [title, setTitle] = useState(null),
        [description, setDescription] = useState(null),
        [episodeNumber, setEpisodeNumber] = useState(null)

    useEffect( () => {
        let subscribed=true
        if (subscribed && scr) {
            setDescription(scr.description ? truncate(scr.description) : '')
            setTitle(scr.name)
            setEpisodeNumber(scr.episode)
            console.log('scr',projectKey, scr)
        }
        return ()=>{subscribed=false}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [scr])

    // useEffect( () => {
    //     let subscribed=true
    //     if (subscribed && activeScreener) {
    //         // console.log('activeScreener',activeScreener)
    //     }
    //     return ()=>{subscribed=false}
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [activeScreener])

    const truncate = (input) => {
        if (input.length > 5) {
            return input.substring(0, 130) + '...';
        }
        return input;
    };

    const prepareScreener = () => {
        prepareProjectScreener(activeKey, scr.key)
            .then(result => {
                scr.screening_details.screener_key = result.data.screening_details.screener_key;
                setActiveScreener(scr);
            });
    }

    return (
        <div onClick={() => {
            prepareScreener()
        }} className={'episodelist__item-container titledetail__mediaitem p-2 mb-2 d-flex '+(activeScreener.screening_details.screener_key===scr.screening_details.screener_key?'active':'')} style={{cursor: 'pointer'}}>
            <div className={'col-3'}>
                <img src={scr.poster ? scr.poster : thumbPlaceholder} alt={title}/>
            </div>
            <div className={'px-3'}>
                <div
                    className={'episodelist__item-title'}>{episodeNumber ? 'Episode ' + episodeNumber + ' - ' + title : projectName + ' - ' + title}</div>
                <div className={'episodelist__item-description'}>{description}</div>
            </div>
        </div>
    )

}

export default ScreenerListItem;
