import { APICore } from "../apiCore";
import {UAParser} from "ua-parser-js";

const api = new APICore();


function generateClientId() {
    const parser = new UAParser(window.navigator.userAgent);
    const { browser, os } = parser.getResult();
    return `WEB-${browser.name.toUpperCase()}-${browser.version}-${os.name.toUpperCase()}${os.version}` || '';
}

const userAgentString = generateClientId();

function indeeAuth(pin) {
    return api.update(`/indee/auth`, { userAgentString, pin })
}

function logout() {
    return api.update(`/indee/logout`, { userAgentString });
}

function fetchIndeePlayer(screenerKey) {
    return api.update(`/indee/screener/${ screenerKey }`, { userAgentString });
}

function fetchIndeeProjects() {
    return api.update('/indee/projects', { userAgentString });
}

function detailProject(projectKey) {
    return api.update(`/indee/project/${ projectKey }`, { userAgentString });
}

function fetchProjectScreeners(projectKey) {
    return api.update(`/indee/project/${ projectKey }/screeners`, { userAgentString });
}

function prepareProjectScreener(projectKey, videoKey) {
    return api.update(`/indee/project/${ projectKey }/load/${ videoKey }`, { userAgentString });
}

function getProjectVideo(projectKey, videoKey) {
    return api.update(`/indee/project/${ projectKey }/video/${ videoKey }`, { userAgentString });
}

export { indeeAuth, logout, fetchIndeePlayer, fetchIndeeProjects, detailProject, fetchProjectScreeners, getProjectVideo, prepareProjectScreener };
