import ReactGA from "react-ga";

export const initGA = (trackingID) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'production') {
        ReactGA.initialize(trackingID, {debug: false});
    }
}

export const gaPageView = () => {
    ReactGA.pageview(window.location.pathname + window.location.search);
}

export const Event = (category, action, label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label
    });
};

export const TrackingEvents = {
    categories: {
        NAV_CATEGORY_FROMHOME:"NAV_CATEGORY_FROMHOME",
        INTERNAL_NAVIGATION:"INTERNAL_NAVIGATION",
        OUTBOUND_NAVIGATION:"OUTBOUND_NAVIGATION",
        SEARCH:"SEARCH"
    },
    actions: {
        NAV_CATEGORY_FROMHOME:"Category navigation via Home Page",
        NAV_RETURN:"Returned to main page from Category",
        NAV_CATEGORY_SUBNAV:"Category navigation via Subnav",
        SEARCH_INPUT_BLUR:"Deselected Search Input with Value: ",
        SEARCH_INPUT_CLEAR:"Clearing Search Value",
        NAV_INTERNAL_LOGO:"Clicked Logo",
        NAV_OUTBOUND_SOCIAL_IG:"Clicked Instagram Icon",
        NAV_OUTBOUND_SOCIAL_TW:"Clicked Twitter Icon",
        NAV_OUTBOUND_HERO:"Clicked WATCH NOW from Hero for: ",
        NAV_INTERNAL_HERO:"Clicked LEARN MORE from Hero for: ",
        NAV_OUTBOUND_TITLE:"Clicked WATCH NOW from Title Page for: ",
        NAV_INTERNAL_TITLE:"Clicked Display Art for: ",
        NAV_OUTBOUND_SEARCH:"Clicked Title Name from Search Results for: ",
        NAV_OUTBOUND_FYSEE_VIDEO:"Clicked FYSEE Video from Home Page for: ",
        NAV_OUTBOUND_FYSEE:"Clicked FYSEE WATCH MORE from Home Page"
    },
    labels: {
        NAV_CATEGORY_FROMHOME:"NAV_CATEGORY_FROMHOME",
        NAV_CATEGORY_SUBNAV:"NAV_CATEGORY_SUBNAV",
        SEARCH_INPUT_BLUR:"SEARCH_INPUT_BLUR",
        SEARCH_INPUT_CLEAR:"SEARCH_INPUT_CLEAR",
        NAV_INTERNAL_LOGO:"NAV_INTERNAL_LOGO",
        NAV_OUTBOUND_HERO:"NAV_OUTBOUND_HERO",
        NAV_INTERNAL_HERO:"NAV_INTERNAL_HERO",
        NAV_OUTBOUND_SOCIAL:"NAV_OUTBOUND_SOCIAL",
        NAV_OUTBOUND_TITLE:"NAV_OUTBOUND_TITLE",
        NAV_OUTBOUND_FYSEE:"NAV_OUTBOUND_FYSEE",
        NAV_INTERNAL_TITLE:"NAV_INTERNAL_TITLE",
        NAV_RETURN:"NAV_RETURN"
    }
}