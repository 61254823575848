import { APICore } from "../apiCore";

const api = new APICore();

function getShowMediaBySlug(showSlug, mediaSlug) {
    return api.get(`/show/${ showSlug }/media/${ mediaSlug }`);
}

function getShowMediaByTag(genreSlug, showSlug, tag) {
    return api.get(`/genre/${ genreSlug }/show/${ showSlug }/media/tag/${ tag }`);
}

export { getShowMediaBySlug, getShowMediaByTag };
