import React, { useLayoutEffect, useState } from 'react'
import {useLocation} from "react-router-dom"
// import Show from "../../../Common/lib/Show/Show";
import SidebarCategoryItem from './SidebarCategoryItem'

const SidebarCategory = ({category}) => {

    const [featuredShows, setFeaturedShows] = useState(null)
    const location = useLocation()

    const selectedType = location.pathname.includes('/pga') ? "pga" : location.pathname.includes('/ves') ? "ves" : "";
    

    const sorter = (array)=> {
        array.sort(function(a, b){
            var nameA = a.title.replace("The ", "").toLowerCase(),
                nameB = b.title.replace("The ", "").toLowerCase();
            // console.log(nameA, nameB)
            if (nameA < nameB) //sort string ascending
                return -1;
            if (nameA > nameB)
                return 1;
            return 0; //default return value (no sorting)
        });
        return array
    }

    useLayoutEffect( () => {
        let subscribed = true;
        if (subscribed) {
            // let showsToParse = '['+category.shows[0]+']';
            let sortedShows = sorter(category.shows)
            // console.log(sortedShows)
            setFeaturedShows(sortedShows)
        }
        return ()=>{subscribed=false}
    }, [category])


    return (
        <li>
          <a href={`/${selectedType ? selectedType + "/" : ""}${category.slug ? category.slug : ""}`} className="">{category.name ? category.name : ""}</a>
          <div className="sidebar__list--flyout">
            <div className="sidebar__list--flyout-inner flex-column flex-sm-row">
              {
                featuredShows ?
                  featuredShows.map((s, i)=>{
                     return <SidebarCategoryItem categorySlug={category.slug} showSlug={s.slug} showTitle={s.title} showTitleTreatment={s.title_treatment} key={i} />
                  })
                :""
              }
              <div className="sidebar__list--flyout-item sidebar__list--flyout-item-seeall p-2 d-flex align-items-center">
                <a href={`/${selectedType ? selectedType + "/" : ""}${category.slug ? category.slug : ""}`} className="btn__see-all">SEE ALL</a>
              </div>
            </div>
          </div>
        </li>
    )
}

export default SidebarCategory
